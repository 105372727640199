module.exports = [
    {
        locale: "de",
        label: "Deutsch",
    },
    {
        locale: "en",
        label: "English",
        default: true,
    },
];
